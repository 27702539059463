
  /* eslint-disable */
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import { ValidatePhoneModal } from '@/components/shared/validate-phone-modal';
  import { PhoneConfirmationModal } from '@/components/shared/phone-confirmation-modal';

  export default Vue.extend({
    name: 'es-pro-phone',

    components: {
      'es-phone-validate-modal': ValidatePhoneModal,
      'es-phone-confirmation-modal': PhoneConfirmationModal,
      'es-phone-number-input': VuePhoneNumberInput,
    },

    data() {
      return {
        actionType: 'add',
        translations: {
          countrySelectorLabel: this.$t('generic.phone_nr_country_selector'),
          countrySelectorError: this.$t('generic.phone_nr_selector_error'),
          phoneNumberLabel: this.$t('generic.phone_nr_label'),
          example: this.$t('generic.phone_nr_example')
        },
        phone: '',
        isPhoneConfirmationModalOpen: false,
        isPhoneValidateModalOpen: false,
      }
    },

    watch: {
      getUser(newVal, oldVal) {
        if (newVal.phone_number !== oldVal.phone_number) {
          this.phone = newVal.phone_number;
        }
      },
    },

    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
    },

    created() {
      const { phone_number } = this.getUser;
      this.phone = phone_number;
      this.actionType = phone_number ? 'modify' : 'add';
    },
  });
