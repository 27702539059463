
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'es-terms-and-conditions-modal',

    model: {
      prop: 'is-open',
      event: 'is-open',
    },

    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
    },

    /* eslint-disable */
    data: () => ({}),
  });
