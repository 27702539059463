
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import { nanoid } from 'nanoid';

  export default Vue.extend({
    name: 'es-pro-recommendations',
    data: () => ({
      code: 'https://ease.ro/pro/',
    }),
    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
    },
    created() {
      const eliteId = this.getUser.elite_id;
      this.code = `https://ease.ro/pro/${eliteId}`;
    },
    methods: {
      onCopy(): void {
        copyToClipboard(this.code);
        (this as any).$toasts.toast({
          id: nanoid(),
          message: this.$t('toast.profile_link_copied'),
          title: this.$t('toast.success_title'),
          intent: 'success',
        });
      },
    },
  });
