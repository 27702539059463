
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-custom-checkbox',

    props: {
      checked: {
        default: false,
        type: [String, Boolean],
      },
    },

    computed: {
      getCheckState(): boolean {
        let status = false;
        if (typeof this.checked === 'boolean') {
          status = this.checked;
        } else {
          status = this.checked !== 'not_uploaded';
        }
        return status;
      },
      isPending(): boolean {
        return this.checked === 'pending';
      },
      isAccepted(): boolean {
        return this.checked === 'accepted';
      },
    },
  });
