
  /* eslint-disable */
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import { ForgotPasswordModal } from '@/components/shared/forgot-password-modal';
  import { ResetPasswordModal } from '@/components/shared/reset-password-modal';

  export default Vue.extend({
    name: 'es-pro-password',

    components: {
      'es-forgot-password-modal': ForgotPasswordModal,
      'es-reset-password-modal': ResetPasswordModal,
    },

    data: () => ({
      isForgotPasswordModalOpen: false,
      isResetPasswordModalOpen: false,
      showPassword: false,
      oldPassword: null,
      newPassword: null,
      newPassType: 'password',
      oldPassType: 'password',
    }),
    methods: {
      ...mapActions({
        changePassword: 'session/changePassword',
      }),
      onSave(): void {
        const { newPassword, oldPassword } = this;
        const hasPassword = this.newPassword && this.oldPassword;

        if (hasPassword) {
          this.changePassword({ old_password: oldPassword, new_password: newPassword });
        } else {
          (this as any).$toasts.toast({
            id: 'password-toast',
            title: this.$t('toast.error_title'),
            message: this.$t('toast.wrong_password'),
            intent: 'warning',
          });
        }
      },
    },
  });
